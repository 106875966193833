<template>
    <div>
        <p class="txt_secondary text-uppercase pt-3"><strong>Horario</strong></p>
        <b-row>
            <b-col cols="12">
                <b-table-simple class="table-sm">
                    <b-tbody> 
                        <b-tr>
                            <b-th class="th_style">Inicia anestesia</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-th class="th_style">Inicia cirugía</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-th class="th_style">Termina cirugía</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-th class="th_style">Egreso de sala</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th colspan="10" class="th_style signos_vitales text-center">Valoración aldrete modificado</b-th>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Criterio</b-th>
                            <b-th class="th_style signos_vitales text-center">Al llegar</b-th>
                            <b-th class="th_style signos_vitales text-center">30 minutos</b-th>
                            <b-th class="th_style signos_vitales text-center">1 hora</b-th>
                            <b-th class="th_style signos_vitales text-center">1:30 horas</b-th>
                            <b-th class="th_style signos_vitales text-center">2 horas</b-th>
                            <b-th class="th_style signos_vitales text-center">2:30 horas</b-th>
                            <b-th class="th_style signos_vitales text-center">3 horas</b-th>
                            <b-th class="th_style signos_vitales text-center">3:30 horas</b-th>
                            <b-th class="th_style signos_vitales text-center">4 horas</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody> 
                        <b-tr>
                            <b-td class="border_table">
                                <p id="popover-target-1" class="txt_secondary">Estado de conciencia</p>
                                <b-popover target="popover-target-1" triggers="hover" placement="right">
                                    <template #title>Respuestas</template>
                                    <b-avatar variant="primary" size="sm" text="2" /> Completamente despierto capaz de contestar.<br>
                                    <b-avatar variant="primary" size="sm" text="1" /> Responde o despierta al ser llamado.<br>
                                    <b-avatar variant="primary" size="sm" text="0" /> No responde a estímulo auditivo.
                                </b-popover>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <p id="popover-target-2" class="txt_secondary">Actividad moscular</p>
                                <b-popover target="popover-target-2" triggers="hover" placement="right">
                                    <template #title>Respuestas</template>
                                    <b-avatar variant="primary" size="sm" text="2" /> Movimientos voluntarios al ordenárselo 4 extremidades.<br>
                                    <b-avatar variant="primary" size="sm" text="1" /> Movimientos voluntarios al ordenárselo 2 extremidades.<br>
                                    <b-avatar variant="primary" size="sm" text="0" /> Completamente inmóvil.
                                </b-popover>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <p id="popover-target-3" class="txt_secondary">Respiración</p>
                                <b-popover target="popover-target-3" triggers="hover" placement="right">
                                    <template #title>Respuestas</template>
                                    <b-avatar variant="primary" size="sm" text="2" /> Respiraciones amplias y capaz de toser.<br>
                                    <b-avatar variant="primary" size="sm" text="1" /> Respiración limitada y/o disnea.<br>
                                    <b-avatar variant="primary" size="sm" text="0" /> Apnea o falta de respiración espontánea.
                                </b-popover>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <p id="popover-target-4" class="txt_secondary">Circulación</p>
                                <b-popover target="popover-target-4" triggers="hover" placement="right">
                                    <template #title>Respuestas</template>
                                    <b-avatar variant="primary" size="sm" text="2" /> Presión arterial de +/20% del nivel preanestésico.<br>
                                    <b-avatar variant="primary" size="sm" text="1" /> Presión arterial de +/20-50% del nivel preanestésico.<br>
                                    <b-avatar variant="primary" size="sm" text="0" /> Presión arterial de +/50% del nivel preanestésico.
                                </b-popover>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <p id="popover-target-5" class="txt_secondary">Color</p>
                                <b-popover target="popover-target-5" triggers="hover" placement="right">
                                    <template #title>Respuestas</template>
                                    <b-avatar class="mt-1" variant="primary" size="sm" text="2" /> Mucosas sonrojantes.<br>
                                    <b-avatar class="mt-1" variant="primary" size="sm" text="1" /> Pálido e ictérico.<br>
                                    <b-avatar class="mt-1" variant="primary" size="sm" text="0" /> Cianótico.
                                </b-popover>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <p id="popover-target-6" class="txt_secondary">Sangrado sitio quirúrgico</p>
                                <b-popover target="popover-target-6" triggers="hover" placement="right">
                                    <template #title>Respuestas</template>
                                    <b-avatar variant="primary" size="sm" text="2" /> No evidencia de sangrado activo 0 ml.<br>
                                    <b-avatar variant="primary" size="sm" text="1" /> Poco a moderado drenaje 0-50 ml.<br>
                                    <b-avatar variant="primary" size="sm" text="0" /> Moderado a excesivo > 50 ml.
                                </b-popover>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="border_table">
                                <p id="popover-target-7" class="txt_secondary">Nausias y vómito</p>
                                <b-popover target="popover-target-7" triggers="hover" placement="right">
                                    <template #title>Respuestas</template>
                                    <b-avatar variant="primary" size="sm" text="2" /> Ausencia de náusias y vómito.<br>
                                    <b-avatar variant="primary" size="sm" text="1" /> Presencia de náusias.<br>
                                    <b-avatar variant="primary" size="sm" text="0" /> Presencia de náusias y vómito.
                                </b-popover>
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                            <b-td class="border_table text-center">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Hora</b-th>
                            <b-th class="th_style signos_vitales text-center">Temp</b-th>
                            <b-th class="th_style signos_vitales text-center">F.R.</b-th>
                            <b-th class="th_style signos_vitales text-center">F.C.</b-th>
                            <b-th class="th_style signos_vitales text-center">T.A.</b-th>
                            <b-th class="th_style signos_vitales text-center">SPO2</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple class="table-sm">
                    <b-tbody> 
                        <b-tr>
                            <b-th class="th_style">Oxígeno litro por minuto</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr> 
                        <b-tr>
                            <b-th class="th_style">Mascarilla</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th class="th_style">Puntas nasales</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr> 
                        <b-tr>
                            <b-th class="th_style">Ventilación mecánica</b-th>
                            <b-td class="border_table">
                                <b-form-input autocomplete="off" type="text" class="border-0" size="sm" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <p class="txt_secondary text-uppercase pt-3"><strong>Revaloración del dolor</strong></p>
            </b-col>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Hora</b-th>
                            <b-th class="th_style signos_vitales text-center">Nivel de dolor</b-th>
                            <b-th class="th_style signos_vitales text-center">Localización</b-th>
                            <b-th class="th_style signos_vitales text-center">Tipo</b-th>
                            <b-th class="th_style signos_vitales text-center">Intervención</b-th>
                            <b-th class="th_style signos_vitales text-center">Escala utilizada</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm">
                    <b-tbody> 
                        <b-tr>
                            <b-th class="th_style">Escala de dowton</b-th>
                            <b-td class="border_table">
                                <b-form-checkbox size="lg" />
                            </b-td>
                            <b-th class="th_style">Escala de dolor postoperatoria en pediátricos de flacc (entre más alta sea más incomodidad hay)</b-th>
                            <b-td class="border_table">
                                <b-form-checkbox size="lg" />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Soluciones parentales</b-th>
                            <b-th class="th_style signos_vitales text-center">Inicio</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table-simple responsive class="table-sm mt-3 table-responsive">
                    <b-thead>
                        <b-tr>
                            <b-th class="th_style signos_vitales text-center">Medicamentos</b-th>
                            <b-th class="th_style signos_vitales text-center">Hora</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>  
                        <b-tr>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                            <b-td class="border_table text-center">
                                <span class="size-span">123</span>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <p class="txt_secondary text-uppercase pt-3"><strong>Reporte de enfermería post-procedimiento</strong></p>
                <b-form-textarea autocomplete="off" class="txtArea_border" size="sm" no-resize  rows="5" max-rows="5" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    data() {
        return  {
            selected: null,
            selectedText: '',
            options: [
                { value: 2, text: 'Completamente despierto capaz de contestar'},
                { value: 1, text: 'Responde o despierta al ser llamado'},
                { value: 0, text: 'No responde a estímulo auditivo'}
            ]
        }
    },

    methods: {
    updateSelectedText() {
        // Buscar el texto correspondiente al valor seleccionado
        const option = this.options.find(opt => opt.value === this.selected);
            if (option) {
            this.selectedText = option.text; // Actualizar el texto seleccionado
            }
        }
    },

    watch: {
        selected() {
        // Actualizar el texto seleccionado cuando cambie el valor seleccionado
        this.updateSelectedText();
        }
    }
}
</script>